import React, { useState, useEffect, useRef } from 'react';
import confetti from 'canvas-confetti';

const CustomCursor = () => {
  const cursorSm = useRef(null);
  const cursorLg = useRef(null);
  const positionRef = useRef({
    mouseX: 0,
    mouseY: 0,
    destinationX: 0,
    destinationY: 0,
    distanceX: 0,
    distanceY: 0,
    key: -1,
  });

  const [hitPoint, setHitPoint] = useState({ show: false, x: 0, y: 0 });
  const [setPoints] = useState(0);
  const [confettiOrigin, setConfettiOrigin] = useState({ x: 0, y: 0 });
  const [showConfettiText, setShowConfettiText] = useState(false);

  useEffect(() => {
    const handleMouseMove = event => {
      const { clientX, clientY } = event;

      positionRef.current.mouseX = clientX - cursorSm.current.clientWidth / 2;
      positionRef.current.mouseY = clientY - cursorSm.current.clientHeight / 2;

      positionRef.current.mouseX = clientX - cursorLg.current.clientWidth / 2;
      positionRef.current.mouseY = clientY - cursorLg.current.clientHeight / 2;
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const followMouse = () => {
      positionRef.current.key = requestAnimationFrame(followMouse);

      const {
        mouseX,
        mouseY,
        destinationX,
        destinationY,
        distanceX,
        distanceY,
      } = positionRef.current;

      if (!destinationX || !destinationY) {
        positionRef.current.destinationX = mouseX;
        positionRef.current.destinationY = mouseY;
      } else {
        positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
        positionRef.current.distanceY = (mouseY - destinationY) * 0.1;

        if (
          Math.abs(positionRef.current.distanceX) +
            Math.abs(positionRef.current.distanceY) <
          0.1
        ) {
          positionRef.current.destinationX = mouseX;
          positionRef.current.destinationY = mouseY;
        } else {
          positionRef.current.destinationX += distanceX;
          positionRef.current.destinationY += distanceY;
        }
      }

      cursorSm.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
      cursorLg.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
    };
    followMouse();
  }, []);

  const handleClick = event => {
    const { pageX, pageY, clientX, clientY } = event;

    const cursorRect = cursorSm.current.getBoundingClientRect();
    if (
      pageX >= cursorRect.left + window.pageXOffset &&
      pageX <= cursorRect.right + window.pageXOffset &&
      pageY >= cursorRect.top + window.pageYOffset &&
      pageY <= cursorRect.bottom + window.pageYOffset
    ) {
      const hitPointX = pageX -10;
      const hitPointY = pageY -30;

      setHitPoint({ show: true, x: hitPointX, y: hitPointY });
      setConfettiOrigin({ x: clientX, y: clientY }); // Set the confetti origin
      setPoints(prevPoints => {
        const newPoints = prevPoints + 200;
        if (newPoints >= 1000) {
          triggerConfetti(clientX, clientY); // Pass viewport-relative position
          return 0;
        }
        return newPoints;
      });

      setTimeout(() => {
        setHitPoint({ show: false, x: 0, y: 0 });
      }, 1000);
    }
  };

  const triggerConfetti = (x, y) => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { x: x / window.innerWidth, y: y / window.innerHeight },
    });

    setShowConfettiText(true);

    // Hide the text after 3 seconds
    setTimeout(() => {
      setShowConfettiText(false);
    }, 3000);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  return (
    <>
      <div className="cs-cursor_lg" ref={cursorLg}></div>
      <div className="cs-cursor_sm" ref={cursorSm}></div>
      {hitPoint.show && (
        <div
          className="hit-point"
          style={{
            position: 'absolute',
            left: hitPoint.x,
            top: hitPoint.y,
            color: 'chartreuse',
            fontSize: '30px',
            fontWeight: 'bold',
            animation: 'fadeOut 1s forwards',
            pointerEvents: 'none',
          }}
        >
          +200
        </div>
      )}
      {showConfettiText && (
        <div
          className="confetti-text"
          style={{
            position: 'fixed',
            left: `${confettiOrigin.x -30}px`,
            top: `${confettiOrigin.y -70}px`,
            transform: 'translate(-50%, -50%)',
            color: 'gold',
            fontSize: '40px',
            fontWeight: 'bold',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
            animation: 'fadeOutText 3s forwards',
            pointerEvents: 'none',
            zIndex: 1000,
          }}
        >
          Let's work together!
        </div>
      )}
      <style jsx>{`
        @keyframes fadeOut {
          0% {
            opacity: 1;
            transform: translateY(0);
          }
          100% {
            opacity: 0;
            transform: translateY(-20px);
          }
        }
        @keyframes fadeOutText {
          0% {
            opacity: 1;
            transform: translateY(0);
          }
          100% {
            opacity: 0;
            transform: translateY(-20px);
          }
        }
      `}</style>
    </>
  );
};

export default CustomCursor;
